import * as Types from '../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
const defaultOptions = {} as const;
export type UserSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserSettingsQuery = {
  readonly __typename?: 'Query';
  readonly response: {
    readonly __typename?: 'TerraUserSettings';
    readonly id: string;
    readonly locale?: Types.TerraLocale | null;
    readonly theme?: Types.TerraTheme | null;
    readonly region?: Types.TerraRegion | null;
  };
};

export type UpdateUserSettingsMutationVariables = Types.Exact<{
  input: Types.TerraUpdateUserSettingsInput;
}>;

export type UpdateUserSettingsMutation = {
  readonly __typename?: 'Mutation';
  readonly response: {
    readonly __typename?: 'TerraUserSettings';
    readonly id: string;
    readonly locale?: Types.TerraLocale | null;
    readonly theme?: Types.TerraTheme | null;
    readonly region?: Types.TerraRegion | null;
  };
};

export const UserSettingsDocument = gql`
  query UserSettings {
    response: terraUserSettings {
      id
      locale
      theme
      region
    }
  }
`;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export function useUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export function useUserSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserSettingsQuery,
        UserSettingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export type UserSettingsQueryHookResult = ReturnType<
  typeof useUserSettingsQuery
>;
export type UserSettingsLazyQueryHookResult = ReturnType<
  typeof useUserSettingsLazyQuery
>;
export type UserSettingsSuspenseQueryHookResult = ReturnType<
  typeof useUserSettingsSuspenseQuery
>;
export type UserSettingsQueryResult = Apollo.QueryResult<
  UserSettingsQuery,
  UserSettingsQueryVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings($input: TerraUpdateUserSettingsInput!) {
    response: terraUpdateUserSettings(input: $input) {
      id
      locale
      theme
      region
    }
  }
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UpdateUserSettingsDocument, options);
}
export type UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationResult =
  Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const namedOperations = {
  Query: {
    UserSettings: 'UserSettings',
  },
  Mutation: {
    UpdateUserSettings: 'UpdateUserSettings',
  },
};

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUserSettingsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { terraUserSettings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUserSettingsQuery = (
  resolver: GraphQLResponseResolver<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<UserSettingsQuery, UserSettingsQueryVariables>(
    'UserSettings',
    resolver,
    options
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateUserSettingsMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { terraUpdateUserSettings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateUserSettingsMutation = (
  resolver: GraphQLResponseResolver<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.mutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >('UpdateUserSettings', resolver, options);
