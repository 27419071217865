'use client';

import { ReactNode, useState } from 'react';
import Cookies from 'js-cookie';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { TerraTheme } from '_src/__codegen__/types';
import { GlobalStyles } from '_src/framework/styles/GlobalStyles';

import { useTerraThemeToTheme } from '../domain/use_terra_theme_to_theme';

import { TerraThemeProvider, useTerraTheme } from './TerraThemeContext';

export type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const state = useState(
    () => (Cookies.get('TERRA_THEME') ?? TerraTheme.System) as TerraTheme
  );

  return (
    <TerraThemeProvider value={state}>
      <ThemeProviderInner>{children}</ThemeProviderInner>
    </TerraThemeProvider>
  );
};

const ThemeProviderInner = ({ children }: ThemeProviderProps) => {
  const [terraTheme] = useTerraTheme();
  const theme = useTerraThemeToTheme(terraTheme);

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  );
};
