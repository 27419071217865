'use client';

import { darkTheme, lightTheme, ThemeType } from '@lunarway/matter-web';

import { useDetectSystemColorScheme } from '_src/framework/utils/use_detect_system_color_scheme';
import { TerraTheme } from '_src/__codegen__/types';

export function useTerraThemeToTheme(theme: TerraTheme): ThemeType {
  const { isDarkMode } = useDetectSystemColorScheme();

  if (theme === TerraTheme.Dark) {
    return darkTheme;
  }

  if (theme === TerraTheme.Light) {
    return lightTheme;
  }

  // Defaults to system theme `TerraTheme.System`
  return isDarkMode ? darkTheme : lightTheme;
}
