import { useCallback } from 'react';
import Cookies from 'js-cookie';

import { useIsMock } from '_src/contexts/mock/useIsMock';
import { useUpdateUserSettingsMutation } from '_src/app/settings/data/__codegen__/UserSettings';
import { TerraTheme } from '_src/__codegen__/types';

import { useTerraTheme } from '../ui/TerraThemeContext';

export const useTerraThemeUpdate = () => {
  const isMock = useIsMock();
  const [, setTerraTheme] = useTerraTheme();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  return useCallback(
    async (theme: TerraTheme) => {
      setTerraTheme(theme);

      Cookies.set('TERRA_THEME', theme, {
        // Safari and Brave limits this to 7 days https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#why-is-my-expiration-time-capped-at-7-days-or-24-hours
        expires: 7,
        domain: isMock ? 'localhost' : 'lunar.app',
        path: '/',
      });

      try {
        await updateUserSettings({
          variables: {
            input: {
              theme,
            },
          },
        });
      } catch (error) {
        // We fail silently. If the mutation fails we fallback to theme defaults.
        // The site listens only to the cookie.
        // In case the cookie should expire or not be there at all we use theme defaults.
      }
    },
    [isMock, setTerraTheme, updateUserSettings]
  );
};
